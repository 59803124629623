var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "scrollContainer",
      staticClass: "scroll-container",
      on: {
        wheel: function ($event) {
          $event.preventDefault()
          return _vm.handleScroll.apply(null, arguments)
        },
        pointerdown: _vm.startDrag,
        pointermove: _vm.handleDrag,
        pointerup: _vm.endDrag,
      },
    },
    [
      _c(
        "div",
        {
          ref: "scrollWrapper",
          staticClass: "scroll-wrapper",
          style: { top: _vm.top + "px" },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }