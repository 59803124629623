var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "scroll-bar",
    [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { draggable: "false", src: _vm.logo },
          on: {
            click: function ($event) {
              _vm.$router.push("/").catch((err) => {})
            },
          },
        }),
      ]),
      _vm.showMenu
        ? _c(
            "el-menu",
            {
              attrs: {
                mode: "vertical",
                "show-timeout": 200,
                "default-active": _vm.$route.path,
                "background-color": "transparent",
                "text-color": "#bfcbd9",
                "active-text-color": "#409EFF",
              },
            },
            [
              !_vm.isExternal
                ? _c(
                    "el-menu-item-group",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/client" } },
                        [
                          _c("el-menu-item", { attrs: { index: "/client" } }, [
                            _vm._v("My plan"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.managedUsers.length
                ? _c(
                    "el-menu-item-group",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "user-menu-header",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.managedUsersGroupName)),
                          ]),
                          _c("el-switch", {
                            model: {
                              value: _vm.hideUserNames,
                              callback: function ($$v) {
                                _vm.hideUserNames = $$v
                              },
                              expression: "hideUserNames",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { staticClass: "client-filter" },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              "suffix-icon": _vm.clientFilter
                                ? ""
                                : "el-icon-search",
                              clearable: "",
                            },
                            model: {
                              value: _vm.clientFilter,
                              callback: function ($$v) {
                                _vm.clientFilter = $$v
                              },
                              expression: "clientFilter",
                            },
                          }),
                          _vm.clientFilter &&
                          _vm.filteredManagedUsers.length === 0
                            ? _c("div", { staticClass: "no-results" }, [
                                _vm._v(" no match "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.filteredManagedUsers, function (user) {
                        return _c(
                          "router-link",
                          {
                            key: user.id,
                            attrs: {
                              to: `/client/${user.id}`,
                              draggable: "false",
                            },
                          },
                          [
                            _c(
                              "el-menu-item",
                              { attrs: { index: `/client/${user.id}` } },
                              [
                                _vm.hideUserNames
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.hideName(user.name))),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(user.name))]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-menu-item-group",
                { attrs: { title: "Settings" } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: `/client/users` } },
                    [
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/client/users" } },
                        [_vm._v(" User Management ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }