<template>
    <el-menu class="navbar" mode="horizontal">
        <i class="el-icon-close close" @click="closeWindow" />
    </el-menu>
</template>

<script>
export default {
    methods: {
        closeWindow() {
            window.close();
        },
    },
};
</script>

<style lang="scss" scoped>
.navbar {
    height: 50px;
    line-height: 50px;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .close {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
</style>
