import firebase from 'firebase/app';

export default {
    user: null, // auth user
    userData: null, // firestore user record
    role: 0, // user role (from userData, or 0)

    ROLE_USER: 0,
    ROLE_ADMIN: 1,
    ROLE_SUPERADMIN: 2,
    ROLE_EXTERNAL: 3,

    authenticated() {
        return new Promise((resolve) => {
            firebase.auth().onAuthStateChanged((user) => {
                this.setUser(user).then(() => {
                    resolve(user);
                });
            });
        }).then((user) => {
            return user && !user.isAnonymous;
        });
    },

    async setUser(user) {
        this.user = user;
        if (user) {
            return firebase
                .firestore()
                .doc(`users/${user.uid}`)
                .get()
                .then((doc) => {
                    this.userData = doc.data();
                    let role = this.userData.role;
                    if (!role || role === 'user') {
                        role = this.ROLE_USER;
                    }
                    this.role = role;
                    if (this.userData.managedUsers) {
                        this.userData.managedUsers.sort((a, b) => {
                            if (a.name) {
                                return a.name.localeCompare(b.name);
                            }
                            return 0;
                        });
                    }
                });
        }
    },
    clearUser() {
        this.user = null;
        this.userData = null;
    },
};
