<template>
    <scroll-bar>
        <div class="logo">
            <img
                draggable="false"
                :src="logo"
                @click="$router.push('/').catch((err) => {})"
            />
        </div>
        <el-menu
            v-if="showMenu"
            mode="vertical"
            :show-timeout="200"
            :default-active="$route.path"
            background-color="transparent"
            text-color="#bfcbd9"
            active-text-color="#409EFF"
        >
            <el-menu-item-group v-if="!isExternal">
                <router-link to="/client">
                    <el-menu-item index="/client">My plan</el-menu-item>
                </router-link>
            </el-menu-item-group>

            <el-menu-item-group v-if="managedUsers.length">
                <div slot="title" class="user-menu-header">
                    <span>{{ managedUsersGroupName }}</span>
                    <el-switch v-model="hideUserNames" />
                </div>
                <li class="client-filter">
                    <el-input
                        v-model="clientFilter"
                        size="small"
                        :suffix-icon="clientFilter ? '' : 'el-icon-search'"
                        clearable
                    >
                    </el-input>
                    <div
                        v-if="clientFilter && filteredManagedUsers.length === 0"
                        class="no-results"
                    >
                        no match
                    </div>
                </li>
                <router-link
                    v-for="user in filteredManagedUsers"
                    :key="user.id"
                    :to="`/client/${user.id}`"
                    draggable="false"
                >
                    <el-menu-item :index="`/client/${user.id}`">
                        <span v-if="hideUserNames">{{
                            hideName(user.name)
                        }}</span>
                        <span v-else>{{ user.name }}</span>
                    </el-menu-item>
                </router-link>
            </el-menu-item-group>

            <el-menu-item-group title="Settings">
                <router-link :to="`/client/users`">
                    <el-menu-item index="/client/users">
                        User Management
                    </el-menu-item>
                </router-link>
            </el-menu-item-group>
        </el-menu>
    </scroll-bar>
</template>

<script>
import ScrollBar from './ScrollBar';
import logo from '@/assets/logo.svg';

import auth from '@/utils/auth';

export default {
    name: 'client-sidebar',
    components: {ScrollBar},
    data() {
        return {
            logo,
            managedUsers: [],
            refreshKey: 1,
            clientFilter: '',
            hideUserNames: false,
        };
    },
    computed: {
        isExternal() {
            return auth.role === auth.ROLE_EXTERNAL;
        },
        showMenu() {
            this.refreshKey;
            return (
                auth.userData.status !== 'pending' &&
                auth.userData.is_temporary_password !== true
            );
        },

        filteredManagedUsers() {
            if (this.clientFilter) {
                return this.managedUsers.filter((u) =>
                    u.name
                        .toLowerCase()
                        .includes(this.clientFilter.toLowerCase())
                );
            }
            return this.managedUsers;
        },

        managedUsersGroupName() {
            return this.isExternal ? 'Users' : 'Other users';
        },

        routes() {
            let role = auth.ROLE_USER;
            // filter out routes for other roles
            let filteredRoutes = [];
            this.$router.options.routes.forEach((route) => {
                if (route.children) {
                    route = {...route};
                    const children = route.children.filter((r) => {
                        return (
                            r.meta === undefined ||
                            r.meta.role === undefined ||
                            r.meta.role === role
                        );
                    });
                    route.children = children;
                }
                if (
                    route.meta === undefined ||
                    route.meta.role === undefined ||
                    route.meta.role === role
                ) {
                    filteredRoutes.push(route);
                }
            });
            return filteredRoutes;
        },
    },
    mounted() {
        this.$bus.$on('refreshAuth', () => {
            this.refreshKey++;
            this.getManagedUsers();
        });
        this.getManagedUsers();
    },
    beforeDestroy() {
        this.$bus.$off('managedUsers');
    },
    methods: {
        getManagedUsers() {
            this.managedUsers = auth.userData.managedUsers || [];
        },
        hideName(name) {
            return `${name.substring(0, 1)}*********`;
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.user-menu-header {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
    ::v-deep {
        .el-switch__core {
            background: transparent;
        }
        .el-switch.is-checked .el-switch__core {
            border-color: $menuBg;
            background-color: $menuBg;
        }
    }
}

::v-deep .el-menu-item-group {
    margin-bottom: 50px;
    .el-menu-item-group__title {
        color: white;
        font-size: 13px;
        font-weight: bold;
    }
}
#app .sidebar-container {
    a .el-menu-item {
        background-color: white !important;
        color: $menuBg !important;
        justify-content: center !important;
        height: 30px;
        line-height: 30px;

        &:hover {
            background-color: $grey !important;
        }

        &.is-active {
            background-color: $menuBg !important;
            color: white !important;
            &:hover {
                background-color: $subMenuBg !important;
            }
        }
    }
    .client-filter {
        padding: 3px 10px;
        ::v-deep .el-input__inner {
            background-color: transparent;
            color: white;
            &:focus {
                background-color: #ffffff0f;
            }
        }
        .no-results {
            text-align: center;
            color: white;
            font-size: 13px;
            padding: 10px 0;
        }
    }
}
</style>
