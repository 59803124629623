var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "scroll-bar",
    [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { draggable: "false", src: _vm.logo },
          on: {
            click: function ($event) {
              _vm.$router.push("/").catch((err) => {})
            },
          },
        }),
      ]),
      _c(
        "el-menu",
        {
          attrs: {
            mode: "vertical",
            "show-timeout": 200,
            "default-active": _vm.$route.path,
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF",
            draggable: "false",
          },
        },
        [_c("sidebar-item", { attrs: { routes: _vm.routes } })],
        1
      ),
      _c("div", [_vm._v("The end")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }