<template>
    <div
        ref="scrollContainer"
        class="scroll-container"
        @wheel.prevent="handleScroll"
        @pointerdown="startDrag"
        @pointermove="handleDrag"
        @pointerup="endDrag"
    >
        <div
            ref="scrollWrapper"
            class="scroll-wrapper"
            :style="{top: top + 'px'}"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
const delta = 15;

export default {
    name: 'scroll-bar',
    data() {
        return {
            top: 0,
            grabPosition: null,
            grabOffset: null,
            clickThreshold: 3,
        };
    },
    methods: {
        handleScroll(event) {
            const eventDelta = event.wheelDelta || -event.deltaY * 3;
            const $container = this.$refs.scrollContainer;
            const $containerHeight = $container.offsetHeight;
            const $wrapper = this.$refs.scrollWrapper;
            const $wrapperHeight = $wrapper.offsetHeight;
            if (eventDelta > 0) {
                this.top = Math.min(0, this.top + eventDelta);
            } else {
                if ($containerHeight - delta < $wrapperHeight) {
                    if (
                        this.top < -($wrapperHeight - $containerHeight + delta)
                    ) {
                        // eslint-disable-next-line no-self-assign
                        this.top = this.top;
                    } else {
                        this.top = Math.max(
                            this.top + eventDelta,
                            $containerHeight - $wrapperHeight - delta
                        );
                    }
                } else {
                    this.top = 0;
                }
            }
        },

        startDrag(event) {
            this.$refs.scrollContainer.setPointerCapture(event.pointerId);
            this.grabPosition = event.clientY;
            this.grabOffset =
                event.clientY -
                this.$refs.scrollContainer.getBoundingClientRect().top -
                this.top;
        },
        handleDrag(event) {
            if (this.grabPosition) {
                const $container = this.$refs.scrollContainer;
                const $containerHeight = $container.offsetHeight;
                const $wrapper = this.$refs.scrollWrapper;
                const $wrapperHeight = $wrapper.offsetHeight;
                const $containerOffset =
                    this.$refs.scrollContainer.getBoundingClientRect().top;

                this.top = Math.min(
                    0,
                    Math.max(
                        event.clientY - $containerOffset - this.grabOffset,
                        $containerHeight - $wrapperHeight
                    )
                );
            }
        },
        endDrag(event) {
            this.$refs.scrollContainer.releasePointerCapture(event.pointerId);
            // If pointer has barely moved, treat as a click on the element under the mouse
            if (
                Math.abs(event.clientY - this.grabPosition) <
                this.clickThreshold
            ) {
                document
                    .elementFromPoint(event.clientX, event.clientY)
                    ?.click();
            }
            this.grabPosition = null;
            this.grabOffset = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $menuBg;
    user-select: none;
    user-drag: none;

    .scroll-wrapper {
        position: absolute;
        width: 100% !important;
    }
}
// invert sidebar colors for clients
.client .scroll-container {
    background-color: $purple-rain;
}
</style>
