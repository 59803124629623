var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    { staticClass: "navbar", attrs: { mode: "horizontal" } },
    [
      _c("i", {
        staticClass: "el-icon-close close",
        on: { click: _vm.closeWindow },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }