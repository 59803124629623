import moment from 'moment';

export function pluralize(time, label) {
    if (time === 1) {
        return time + label;
    }
    return time + label + 's';
}

export function timeAgo(value) {
    return moment(value.seconds * 1000).fromNow();
}

export function smart_capitalize(value) {
    if (value === null || value === undefined) return;
    value = value.split('_').join(' ');
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function capitalize(value) {
    if (value === null || value === undefined) return;
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function titleCase(value) {
    if (value === null || value === undefined) return;
    return value
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            if (word) {
                return word.replace(word[0], word[0].toUpperCase());
            }
        })
        .join(' ');
}

export function milliseconds(time) {
    return Math.floor(time / 1000);
}

export function html2Text(val) {
    const div = document.createElement('div');
    div.innerHTML = val;
    return div.textContent || div.innerText;
}

export function toThousandslsFilter(num) {
    return (+num || 0)
        .toString()
        .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

export function currency(value, abs = false) {
    if (typeof value !== 'number' && isNaN(parseFloat(value))) return value;
    else value = parseFloat(value);

    if (abs) {
        value = Math.abs(value);
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
}

export function dateformat(value, format) {
    if (value === null || value === undefined) return '';
    if (!format) format = 'MMM D, YYYY';

    if (typeof value === 'string' || value instanceof String) {
        var date = moment();

        if (value.split(' ').length === 2) {
            date = moment(value);
        } else {
            date = moment(value, 'DD-MM-YYYY');
        }
        if (!date.isValid()) return '';
        return date.format(format);
    } else {
        return moment(value).format(format);
    }
}

export function timestamp2Date(value, format) {
    if (value === null || value === undefined) return '';
    if (!format) format = 'MMM D, YYYY';

    return new Date(moment.unix(value).format(format));
}

export function timestamp2Time(value) {
    if (value === null || value === undefined) return '';
    if (value instanceof Date) return value;
    return moment.unix(value).toDate();
}

export function daysDiff(value) {
    return moment(new Date(value)).diff(moment().startOf('day'), 'days');
}

export function createKeywords(phrase) {
    const keywords = [];
    if (!phrase) return keywords;

    phrase = phrase.toLowerCase();

    // split phrase into list of words
    while (phrase.length > 0) {
        phrase = phrase.trim();
        let currentKeyword = '';
        // starting with the current word,
        phrase.split('').forEach((letter) => {
            // create a 'starts-with' keyword list until the end of the phrase
            currentKeyword += letter;
            keywords.push(currentKeyword);
        });
        // remove the current word and repeat
        const index = phrase.indexOf(' ');
        if (index >= 0) {
            phrase = phrase.slice(index);
        } else phrase = '';
    }

    return keywords;
}

//******************************
//
// APP HELPERS
//
//******************************

export function flattenFirebaseArray(array) {
    if (!array) return null;
    return Object.keys(array).map((key, index) => {
        return {...{'.key': key}, ...array[key]};
    });
}

export function getActivePlan(plans, allowExpired) {
    if (!plans) return null;
    const now = moment();
    let isLoading = false;
    let active_plan = plans.filter((plan) => {
        if (typeof plan === 'string' || plan instanceof String) {
            // some plan data is still loading
            isLoading = true;
            return false;
        }

        if (plan.start) {
            var startDate = moment.unix(plan.start.seconds).subtract(1, 'day');
            var endDate = moment.unix(plan.expiry.seconds).add(1, 'day');

            return now.isBetween(startDate, endDate);
        } else {
            if (!plan.expiry) return true;
            return moment.unix(plan.expiry.seconds).isAfter(now);
        }
    });
    if (active_plan.length > 0) return active_plan[0];
    else if (isLoading) return false;
    // no plans yet, but still loading
    else return null; // no plans
}

export function getExpiredPlans(plans, flatten = false) {
    if (!plans) return null;

    if (flatten) plans = this.flattenFirebaseArray(plans);

    let p = plans
        .filter((plan) => moment().isAfter(moment.unix(plan.expiry.seconds)))
        .sort(function (a, b) {
            return (
                moment.unix(a.expiry.seconds) - moment.unix(b.expiry.seconds)
            );
        });
    return p;
}

export function getLastExpiredPlan(plans, flatten = false) {
    if (!plans) return null;

    let sorted = this.getExpiredPlans(plans, flatten);

    if (sorted.length > 0) return sorted[sorted.length - 1];
    else return null;
}
