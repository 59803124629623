var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    _vm._l(_vm.routes, function (item, idx) {
      return _c(
        "div",
        { key: idx },
        [
          !item.hidden && item.children
            ? [
                !item.hidden
                  ? [
                      _vm._l(item.children, function (child) {
                        return [
                          child.meta && !child.hidden
                            ? _c(
                                "router-link",
                                {
                                  key: child.name,
                                  attrs: {
                                    to: item.path + "/" + child.path,
                                    draggable: "false",
                                  },
                                },
                                [
                                  _c(
                                    "el-menu-item",
                                    {
                                      attrs: {
                                        index: item.path + "/" + child.path,
                                      },
                                    },
                                    [
                                      child.meta && child.meta.title
                                        ? _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(child.meta.title) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }