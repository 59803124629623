<template>
    <el-menu class="navbar" mode="horizontal">
        <span class="navbar-message">{{ navbarMessage }}</span>
        <el-dropdown class="settings-container" trigger="click">
            <div class="settings-wrapper">
                <svgicon name="settings"></svgicon>
            </div>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
                <el-dropdown-item>
                    <span style="display: block" @click="logout">Logout</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </el-menu>
</template>

<script>
import firebase from 'firebase/app';
import auth from '@/utils/auth';

export default {
    computed: {
        navbarMessage() {
            if (
                auth.role !== auth.ROLE_ADMIN &&
                auth.role !== auth.ROLE_SUPERADMIN
            ) {
                return 'Page may require reloading to see any changes made to your data since logging in.';
            }
            return '';
        },
    },
    methods: {
        logout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$router.push('/login');
                });
        },
    },
};
</script>

<style lang="scss" scoped>
hr {
    border: none;
    height: 1px;
    background-color: #eef1f6;
}

.navbar {
    height: 50px;
    line-height: 50px;
    border-radius: 0px !important;

    .screenfull {
        position: absolute;
        right: 90px;
        top: 16px;
        color: red;
    }

    .settings-container {
        height: 50px;
        display: inline-block;
        position: absolute;
        right: 10px;

        .settings-wrapper {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;

            svg {
                width: 35px;
                height: 35px;
            }
        }
    }
    .navbar-message {
        font-size: 13px;
        padding: 0 20px;
        opacity: 0.5;
    }
}
</style>
