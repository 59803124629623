import Vue from 'vue';
import App from './App';
import router from './router';
import UserbackPlugin from '@userback/vue2';

/* ========== SENTRY ============ */

import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

if (process.env.NODE_ENV == 'staging' || process.env.NODE_ENV == 'production') {
    Sentry.init({
        dsn: 'https://7dadb05d46074008bba25251f8ac245b@o314497.ingest.sentry.io/5423886',
        integrations: [
            new VueIntegration({Vue, attachProps: true, logErrors: true}),
        ],
        environment: process.env.NODE_ENV,
    });
}

/* ========== FIREBASE ============ */

import '@/utils/firebase';
import '@/utils/permission';

import {firestorePlugin} from 'vuefire';
Vue.use(firestorePlugin);

import VueGlobalVariable from 'vue-global-var';
import {firebaseDB, firebaseFirestore, firebaseStorage} from '@/utils/firebase';
import {EventBus} from '@/utils/event-bus.js';

Vue.use(VueGlobalVariable, {
    globals: {
        $db: firebaseDB,
        $fire: firebaseFirestore,
        $bus: EventBus,
        $storage: firebaseStorage,
    },
});

/* ========== COMPONENTS ============ */

Vue.config.productionTip = false;

import SvgIcon from 'vue-svgicon';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

import '@/assets/scss/index.scss'; // Global CSS
Vue.use(Element, {locale});
Vue.use(SvgIcon, {tagName: 'svgicon'});

/* ========== FILTERS ============ */

import * as filters from './filters'; // Global filters

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

/* ========== USERBACK ============ */

if (process.env.NODE_ENV !== 'development') {
    Vue.use(UserbackPlugin, {token: 'A-UqqJHRylaxhqintFaQ51fU8nl'});
}

/* ========== APP ============ */

new Vue({
    el: '#app',
    router,
    render: (h) => h(App),
});
