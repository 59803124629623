var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: { client: !_vm.isAdmin, "single-view": _vm.singleView },
    },
    [
      !_vm.singleView
        ? [
            _vm.isAdmin
              ? _c("sidebar", { staticClass: "sidebar-container" })
              : _c("client-sidebar", { staticClass: "sidebar-container" }),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main-container" },
        [_vm.singleView ? _c("navbar-single") : _c("navbar"), _c("app-main")],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }