import router from '../router';
import {validateRoute} from '@/utils/validate';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import auth from '@/utils/auth';

const whiteList = ['/login', '/forgot'];

router.beforeEach((to, from, next) => {
    NProgress.start();

    auth.authenticated()
        .then(() => {
            if (auth.user) {
                // User is logged in

                if (
                    to.path != '/reset' &&
                    auth.userData.is_temporary_password
                ) {
                    // user must chamge their password before proceeding
                    next('/reset');
                    NProgress.done();
                } else if (to.path === '/') {
                    if (
                        auth.role === auth.ROLE_ADMIN ||
                        auth.role === auth.ROLE_SUPERADMIN
                    ) {
                        // Send admin users to the search page
                        next('/search');
                        NProgress.done();
                    } else if (
                        auth.role === auth.ROLE_USER ||
                        auth.role === auth.ROLE_EXTERNAL
                    ) {
                        // send other users to the user homepage
                        let homepage = '/client';
                        if (
                            auth.role === auth.ROLE_EXTERNAL &&
                            auth.userData.managedUsers &&
                            auth.userData.managedUsers.length
                        ) {
                            // redirect external users to first account, if available
                            homepage += '/' + auth.userData.managedUsers[0].id;
                        }
                        next(homepage);
                        NProgress.done();
                    }
                } else if (to.path === '/login') {
                    // User wants to access auth pages - redirect to plan page
                    next('/');
                    NProgress.done();
                } else {
                    // User wants to access dashboard pages - everything is ok
                    next();
                }
            } else {
                // User is not logged in
                if (validateRoute(to.path, whiteList)) {
                    next();
                } else {
                    next('/login');
                    NProgress.done();
                }
            }
        })
        .catch((e) => {
            console.log('Permission error:', e);
        });
});

router.afterEach(() => {
    NProgress.done();
});
