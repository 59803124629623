<template>
    <div class="menu-wrapper">
        <div v-for="(item, idx) in routes" :key="idx">
            <template v-if="!item.hidden && item.children">
                <template v-if="!item.hidden">
                    <template v-for="child in item.children">
                        <router-link
                            v-if="child.meta && !child.hidden"
                            :key="child.name"
                            :to="item.path + '/' + child.path"
                            draggable="false"
                        >
                            <el-menu-item :index="item.path + '/' + child.path">
                                <span
                                    v-if="child.meta && child.meta.title"
                                    slot="title"
                                >
                                    {{ child.meta.title }}
                                </span>
                            </el-menu-item>
                        </router-link>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import '@/assets/icons';

export default {
    name: 'sidebar-item',
    props: {
        routes: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
