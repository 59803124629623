var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    { staticClass: "navbar", attrs: { mode: "horizontal" } },
    [
      _c("span", { staticClass: "navbar-message" }, [
        _vm._v(_vm._s(_vm.navbarMessage)),
      ]),
      _c(
        "el-dropdown",
        { staticClass: "settings-container", attrs: { trigger: "click" } },
        [
          _c(
            "div",
            { staticClass: "settings-wrapper" },
            [_c("svgicon", { attrs: { name: "settings" } })],
            1
          ),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "user-dropdown",
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _c("el-dropdown-item", [
                _c(
                  "span",
                  {
                    staticStyle: { display: "block" },
                    on: { click: _vm.logout },
                  },
                  [_vm._v("Logout")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }