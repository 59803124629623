import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Layout from '../views/layout/Layout';
import auth from '@/utils/auth';

export const routesMap = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true,
    },
    {
        path: '/forgot',
        component: () => import('@/views/login/forgot'),
        hidden: true,
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true,
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true,
    },
    {
        path: '',
        component: Layout,
        children: [
            {
                path: 'search',
                component: () => import('@/views/users/search'),
                name: 'search',
                meta: {title: 'Home', icon: 'user', role: auth.ROLE_ADMIN},
            },
            {
                path: 'users',
                component: () => import('@/views/users/index'),
                name: 'active_users',
                meta: {
                    title: 'Active users',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
                props: (route) => ({
                    view: 'active',
                    initial_search: route.query.q,
                }),
            },
            {
                path: 'inactive',
                component: () => import('@/views/users/index'),
                name: 'inactive_users',
                meta: {
                    title: 'Inactive users',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
                props: {view: 'inactive'},
            },
            {
                path: 'budgets',
                component: () => import('@/views/users/budgets'),
                name: 'budgets',
                meta: {
                    title: 'Low budgets',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
            },
            {
                path: 'unused',
                component: () => import('@/views/users/unused'),
                name: 'unused',
                meta: {
                    title: 'Unused funds 🐢', // 🐢indicates a slow operation
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
            },
            {
                path: 'expiry',
                component: () => import('@/views/users/expiry'),
                name: 'expiry',
                meta: {
                    title: 'Expired plans',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
            },
            {
                path: 'pending',
                component: () => import('@/views/claims/pending'),
                name: 'pending_approval',
                meta: {
                    title: 'Pending approval',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
            },
            {
                path: 'rejected',
                component: () => import('@/views/claims/rejected'),
                name: 'rejected_claims',
                meta: {
                    title: 'Rejected claims',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
            },
            {
                path: 'claims',
                component: () => import('@/views/claims/index'),
                name: 'claims_due',
                meta: {
                    title: 'Claims due',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
            },
            /*
            {
                path: 'advanced',
                component: () => import('@/views/users/advanced'),
                name: 'advanced',
                meta: {title: 'Advanced view', icon: 'user'},
                props: {view: 'all'},
            },
            */
            {
                path: 'user/new',
                component: () => import('@/views/users/profile'),
                name: 'new-profile',
                meta: {
                    title: 'New profile',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
                hidden: true,
            },
            {
                path: 'user/:user_id',
                component: () => import('@/views/users/profile'),
                name: 'user-profile',
                meta: {
                    title: 'User profile',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
                hidden: true,
            },
            {
                path: 'client',
                component: () => import('@/views/client/index'),
                name: 'client',
                meta: {title: 'Client', role: auth.ROLE_USER},
            },
            {
                path: 'client/users',
                component: () => import('@/views/client/users'),
                name: 'settings-user',
                meta: {title: 'User management', role: auth.ROLE_USER},
            },
            {
                path: 'client/:user_id',
                component: () => import('@/views/client/index'),
                name: 'client-profile',
                meta: {title: 'Managed user', role: auth.ROLE_USER},
            },
            {
                path: 'external',
                component: () => import('@/views/external/index'),
                name: 'external',
                meta: {title: 'External users', role: auth.ROLE_ADMIN},
            },
            {
                path: 'external/:user_id',
                component: () => import('@/views/external/profile'),
                name: 'external-profile',
                meta: {
                    title: 'External user profile',
                    icon: 'user',
                    role: auth.ROLE_ADMIN,
                },
                hidden: true,
            },
            {
                path: 'admin',
                component: () => import('@/views/admin/index'),
                name: 'admin',
                meta: {title: 'Admin users', role: auth.ROLE_SUPERADMIN},
            },
            {
                path: 'reset',
                component: () => import('@/views/login/reset'),
                name: 'reset',
                hidden: true,
                meta: {title: 'Reset password'},
            },
            {
                path: 'scan',
                component: () => import('@/views/util/index'),
                name: 'scan',
                hidden: true,
                meta: {title: 'Scan budget totals', role: auth.ROLE_SUPERADMIN},
            },
        ],
    },
];

const router = new Router({
    mode: 'history',
    routes: routesMap,
});

router.beforeResolve((to, from, next) => {
    let accessLevel = auth.ROLE_USER;
    if (auth.role === auth.ROLE_ADMIN || auth.role === auth.ROLE_SUPERADMIN) {
        accessLevel = auth.ROLE_ADMIN;
    }
    if (
        to.meta === undefined ||
        to.meta.role === undefined ||
        to.meta.role === accessLevel ||
        to.meta.role === auth.role
    ) {
        next();
    } else {
        next('/');
    }
});

export default router;
