import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import {randomBytes} from 'crypto';

let config = {
    apiKey: process.env.VUE_APP_FIREBASE_API,
    authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DB,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT,
    storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER,
};

export const firebaseApp = firebase.initializeApp(config);
export const firebaseDB = firebaseApp.database();
export const firebaseFirestore = firebaseApp.firestore();
export const firebaseStorage = firebaseApp.storage().ref();

firebaseFirestore.settings({experimentalForceLongPolling: true});

export const generateUUID = () => {
    const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    while (autoId.length < 20) {
        const bytes = randomBytes(40);
        bytes.forEach((b) => {
            // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
            // (both inclusive). The value is then evenly mapped to indices of `char`
            // via a modulo operation.
            const maxValue = 62 * 4 - 1;
            if (autoId.length < 20 && b <= maxValue) {
                autoId += chars.charAt(b % 62);
            }
        });
    }
    return autoId;
};
