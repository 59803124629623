<template>
    <scroll-bar>
        <div class="logo">
            <img
                draggable="false"
                :src="logo"
                @click="$router.push('/').catch((err) => {})"
            />
        </div>
        <el-menu
            mode="vertical"
            :show-timeout="200"
            :default-active="$route.path"
            background-color="#304156"
            text-color="#bfcbd9"
            active-text-color="#409EFF"
            draggable="false"
        >
            <sidebar-item :routes="routes" />
        </el-menu>
        <div>The end</div>
    </scroll-bar>
</template>

<script>
import SidebarItem from './SidebarItem';
import ScrollBar from './ScrollBar';
import logo from '@/assets/logo.svg';
import auth from '@/utils/auth';

export default {
    name: 'sidebar',
    components: {SidebarItem, ScrollBar},
    data() {
        return {
            logo,
        };
    },
    computed: {
        routes() {
            let roles = [auth.ROLE_ADMIN];
            if (auth.role === auth.ROLE_SUPERADMIN) {
                roles.push(auth.ROLE_SUPERADMIN);
            }
            // filter out routes for other roles
            let filteredRoutes = [];
            this.$router.options.routes.forEach((route) => {
                if (route.children) {
                    route = {...route};
                    const children = route.children.filter((r) => {
                        return (
                            r.meta === undefined ||
                            r.meta.role === undefined ||
                            roles.includes(r.meta.role)
                        );
                    });
                    route.children = children;
                }
                if (
                    route.meta === undefined ||
                    route.meta.role === undefined ||
                    roles.includes(route.meta.role)
                ) {
                    filteredRoutes.push(route);
                }
            });
            return filteredRoutes;
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
</style>
