<template>
    <div
        class="app-wrapper"
        :class="{client: !isAdmin, 'single-view': singleView}"
    >
        <template v-if="!singleView">
            <sidebar v-if="isAdmin" class="sidebar-container"></sidebar>
            <client-sidebar v-else class="sidebar-container"></client-sidebar>
        </template>
        <div class="main-container">
            <navbar-single v-if="singleView" />
            <navbar v-else />
            <app-main></app-main>
        </div>
    </div>
</template>

<script>
import {
    Navbar,
    NavbarSingle,
    Sidebar,
    AppMain,
} from '@/views/layout/components';
import ClientSidebar from '@/views/layout/components/Sidebar/ClientSidebar';
import auth from '@/utils/auth';

export default {
    name: 'layout',
    components: {
        Navbar,
        NavbarSingle,
        Sidebar,
        ClientSidebar,
        AppMain,
    },
    data() {
        return {
            singleView: false,
        };
    },
    computed: {
        isAdmin() {
            return (
                auth.role === auth.ROLE_ADMIN ||
                auth.role === auth.ROLE_SUPERADMIN
            );
        },
    },
    mounted() {
        if (this.$route.query?.view === 'single') {
            this.singleView = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}
</style>
